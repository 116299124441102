import React from 'react';
import {useDropzone} from 'react-dropzone';
import CryptoJS from 'crypto-js'

async function getMd5(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (fileEvent) => {
            let binary = CryptoJS.lib.WordArray.create(fileEvent.target.result)
            const md5 = CryptoJS.MD5(binary)
            resolve(md5)
            console.log(binary)
            console.log(md5)
        }
        reader.onerror = () => {
            reject('oops, something went wrong with the file reader.')
        }
        reader.readAsArrayBuffer(file)
    })
}

export  default function Basic(props) {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    React.useEffect(() =>{
        if (acceptedFiles.length > 0) {
            const md5 =    getMd5(acceptedFiles[0])

        }
    })



    console.log(acceptedFiles);

    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
            </aside>
        </section>
    );
}
