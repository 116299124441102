import {createRoot} from 'react-dom/client';
import DemoSiteRequest from "../../javascript/react/components/demo_site_request/DemoSiteRequest";
import Basic from "../../javascript/react/components/dropzone/DropZone";
import React from 'react';

document.addEventListener('DOMContentLoaded', () => {
    const rootContainer = document.getElementById('root');
    if (rootContainer) {
        const root = createRoot(rootContainer);
        root.render(<DemoSiteRequest/>);
    }

    const dropzoneContainer = document.getElementById('dropzone');
    if (dropzoneContainer) {
        const root = createRoot(dropzoneContainer);
        root.render(<Basic/>);
    }
})
new EventSource(' http://127.0.0.1:8000/esbuild').addEventListener('change', () => location.reload())
