import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import { TextField } from "@mui/material";
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import theme from "../../theme";

const Content = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 50px;
    background-color: white;
    border-radius: 6px;
    min-width: 300px;
`

const Title = styled.span`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
`

export default function DemoSiteRequest() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Button onClick={handleOpen}>Создать демо-сайт</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Content>
                        <Title>Заявка на демо-сайт</Title>
                        <TextField fullWidth label="Название" variant="outlined"/>
                        <TextField fullWidth label="ИНН" variant="outlined"/>
                        <TextField fullWidth label="Фамилия" variant="outlined"/>
                        <TextField fullWidth label="Имя" variant="outlined"/>
                        <TextField fullWidth label="Отчество" variant="outlined"/>
                        <TextField fullWidth label="Телефон" variant="outlined"/>
                        <TextField fullWidth label="Email" variant="outlined"/>
                        <Button color='success' variant="contained">Отправить</Button>
                    </Content>
                </Modal>
            </>
        </ThemeProvider>
    );
}


