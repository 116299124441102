import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        success: {
            main: '#388E3C',
        },
    },
    typography: {
        fontFamily: 'Montserrat Variable, sans-serif',
    },
});

 export default theme
